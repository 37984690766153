import React, { useState, useEffect } from "react";
import { Link } from "gatsby";


const Footer = (props) => {

    let language_id;
    let [langKey, setLangKey] = useState(props.props.pathContext.langKey);
    if(langKey == "en") language_id = 1;
    else language_id = 2;

    // let location_url = window.location.pathname;
    // console.log(location_url);

    return (
        <>
            <footer className="main_footer">
                <section className="footer_content main_content_wrapper">
                    {language_id == 2 && (
                        <address className="virtulleum_address">
                            <h4>Kontakt</h4>
                            Stadtmuseum Tulln <br />
                            Virtulleum <br />
                            Marc-Aurel-Park 1b <br />
                            A-3430 Tulln an der Donau <br />
                            <br />
                            Tel: <a href="tel:+432272690189" aria-labelledby="Telefon Nummer von Stadtmuseum Tulln" rel="noopener noreferrer">+43 (0) 2272 690 189</a><br />
                            E-mail: <a href="mailto:virtulleum@stadtmuseum-tulln.at" aria-labelledby="E-mail von Stadtmuseum Tulln" rel="noopener noreferrer">virtulleum@stadtmuseum-tulln.at</a>
                        </address>
                    )}

                    {language_id == 1 && (
                        <address className="virtulleum_address">
                            <h4>Contact</h4>
                            Stadtmuseum Tulln<br />
                            Virtulleum <br />
                            Marc-Aurel-Park 1b <br />
                            A-3430 Tulln an der Donau <br />
                            <br />
                            Tel: <a href="tel:+432272690189" aria-labelledby="Phone Number for Stadtmuseum Tulln" rel="noopener noreferrer">+43 (0) 2272 690 189</a><br />
                            E-mail: <a href="mailto:virtulleum@stadtmuseum-tulln.at" aria-labelledby="E-mail on Stadtmuseum Tulln" rel="noopener noreferrer">virtulleum@stadtmuseum-tulln.at</a>
                        </address>
                    )}

                    {language_id == 2 && (
                        <nav className="footer_nav">
                            <ul>
                                <li>
                                    <Link to="/projekt-virtulleum">
                                        Über uns
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        FAQ
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/impressum">Impressum</Link>
                                </li>
                                <li>
                                    <Link to="/privacy">Datenschutz</Link>
                                </li>
                            </ul>
                        </nav>
                    )}

                    {language_id == 1 && (
                        <nav className="footer_nav">
                            <ul>
                                <li>
                                    <Link to="/en/projekt-virtulleum">
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/en/faq">
                                        FAQ
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/en/impressum">Imprint</Link>
                                </li>
                                <li>
                                    <Link to="/en/privacy">Privacy</Link>
                                </li>
                            </ul>
                        </nav>
                    )}

                    <nav className="footer_socials">
                        <ul>
                            <li>
                                {language_id == 2 && (
                                    <a href="http://erleben.tulln.at/" target="_blank" aria-labelledby="Link zu Tulln erleben Seite" rel="noopener noreferrer">
                                        <img
                                            src="/images/logo_tulln_erleben.svg"
                                            alt="Tulln erleben Logo"
                                        />
                                    </a>
                                )}

                                {language_id == 1 && (
                                    <a href="http://erleben.tulln.at/en/" target="_blank" aria-labelledby="Link to Experience Tulln Website" rel="noopener noreferrer">
                                        <img
                                            src="/images/logo_tulln_erleben.svg"
                                            alt="Experience Tulln Logo"
                                        />
                                    </a>
                                )}
                            </li>
                        </ul>
                    </nav>
                </section>

                <div className="footer_leaf_deco_1"></div>
                <div className="footer_leaf_deco_2"></div>
            </footer>
        </>
    );
};

export default Footer;
