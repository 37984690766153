import React, { useEffect } from 'react';

const GA4Tracking = ({ trackingId, isConsentGiven }) => {
  useEffect(() => {
    if (isConsentGiven) {
      // Add GA4 script tag to the head of the document
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      script.async = true;
      document.head.appendChild(script);

      // Initialize GA4
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', trackingId);
    }
  }, [trackingId, isConsentGiven]);

  return null; // This component does not render anything
};

export default GA4Tracking;
