import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Location } from '@reach/router';

import Logo from "./logo";
import HamburgerNav from "./hamburgernav";

const Header = (props) => {
    
    let [isOpen, setIsOpen] = useState(false);
    let [activeHash, setActiveHash] = useState(null);

    
    let openHamburger = (param1) => {
        setIsOpen(true);
    }

    let closeHamburger = () => {
        setIsOpen(false);
    };

    // let location_url = window.location.pathname;

    // set current language
    let language_id;

    let langKey = props.props.pathContext.langKey;
    if(langKey == "en") language_id = 1;
    else language_id = 2;

    
    let url_lang_slug;
    // set language slug for menu urls
    if(language_id == 1) url_lang_slug = "/en/";
    else url_lang_slug = "/";
    


    const translations = {
        home : {
            1: "Home",
            2: "Home"
        },
        museum_objects : {
            1: "Museum Objects",
            2: "Museumsobjekte"
        },
        your_visit : {
            1: "Your Visit",
            2: "Ihr Besuch "
        },
        town_history : {
            1: "City history",
            2: "Stadtgeschichte"
        }
    }

    
    return (
       
        <header className="main_header">
            <Location>
            {({ location })=> {
                setActiveHash(location.hash)
            }}
            </Location>
            <div className="main_header_wrapper main_content_wrapper">
                <button
                    className="hamburger"
                    type="button"
                    onClick={openHamburger}
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>

                <div className="virtulleum_logo">
                    <li>
                        {language_id == 2 && (
                            <Link to="/#top" aria-current={activeHash === "#top" ? "page" : ""}>
                                <Logo />
                            </Link>
                        )}
                        {language_id == 1 && (
                            <Link to="/en/#top" aria-current={activeHash === "#top" ? "page" : ""}>
                                <Logo />
                            </Link>
                        )}
                    </li>
                </div>

                <nav className="header_nav">
                    {language_id == 2 && (
                        <ul>
                            <li>
                                <Link to="/#top" aria-current={activeHash === "#top" ? "page" : ""}>{translations.home[language_id]}</Link>
                            </li>

                            <li>
                                <Link to="/#museumsobjekte" aria-current={activeHash === "#museumsobjekte" ? "page" : ""}>{translations.museum_objects[language_id]}</Link>
                            </li>

                            <li>
                                <Link to="/stadtgeschichte">{translations.town_history[language_id]}</Link>
                            </li>

                            <li>
                                <Link to="/besucherinfos">{translations.your_visit[language_id]}</Link>
                            </li>
                        </ul>
                    )}

                     {language_id == 1 && (
                        <ul>
                            <li>
                                <Link to="/en/#top" aria-current={activeHash === "#top" ? "page" : ""}>{translations.home[language_id]}</Link>
                            </li>

                            <li>
                                <Link to="/en/#museumsobjekte" aria-current={activeHash === "#museumsobjekte" ? "page" : ""}>{translations.museum_objects[language_id]}</Link>
                            </li>

                            <li>
                                <Link to="/en/stadtgeschichte">{translations.town_history[language_id]}</Link>
                            </li>

                            <li>
                                <Link to="/en/besucherinfos">{translations.your_visit[language_id]}</Link>
                            </li>
                        </ul>
                    )}
                </nav>

                
                <nav className="lang_nav">
                    <ul>
                        <li>
                            <Link to="/en/">EN</Link>
                        </li>

                        <li>
                            <Link to="/">DE</Link>
                        </li>
                    </ul>
                </nav>

                <HamburgerNav isOpen={isOpen} onClose={closeHamburger} lang_id={language_id} />
            </div>
        </header>
    );
};


export default Header;
