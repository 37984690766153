import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {default as GatsbyImage} from 'gatsby-image';
import { Link } from "gatsby";
import { Location } from "@reach/router";

import {filter} from 'lodash';


const Specialexhibition = (props) => {

    let language_id;
    let [langKey, setLangKey] = useState(props.props.pathContext.langKey);
    if(langKey == "en") language_id = 1;
    else language_id = 2;

    let url_slug = "/";
    if(language_id == 1) url_slug = "/en/";
    else url_slug = "/";

    
    // get main objects data
    const data = useStaticQuery(SPECIAL_EXHIBITION_DATA);
    let specialExhibition = filter(data.allSpecialExhibition.nodes, function(item){ return item.language_id == language_id})[0];

    let [isSectionOpen, setIsSectionOpen] = useState(false);

    let openExhibitionSection = (isSectionOpen) => {
        setIsSectionOpen(!isSectionOpen);
    }

    const translations = {
        more_info : {
            1: "more information",
            2: "mehr dazu"
        }
    }


    return (
        <>
            {specialExhibition.hidden === "0" && (
                <section className={"special_exhibition" + (isSectionOpen ? " special_exhibition_opened" : "")}> 

                    <img src={specialExhibition.image_url} alt="Get Virtulleum in App Store Icon"/>
                    {/* <GatsbyImage fluid={specialExhibition.specialExhibitionImage.childImageSharp.fluid} alt="Bild von Sonderaustellung" /> */}
                    <div className="image_overlay"></div>
                    <h1>{specialExhibition.title}</h1>

                    <div
                        className="description"
                        dangerouslySetInnerHTML={{
                            __html: specialExhibition.description
                        }}
                    ></div>

                    {specialExhibition.link_more !== "" && (
                        <a href={specialExhibition.link_more} target="_blank" rel="noopener noreferrer" className="rounded_btn white_btn special_exhibition_btn js_special_exhibition_btn">{translations.more_info[language_id]}</a>
                    )}

                    {specialExhibition.link_more === "" && (
                        <a href={`${url_slug}news`} rel="noopener noreferrer" className="rounded_btn white_btn special_exhibition_btn js_special_exhibition_btn">{translations.more_info[language_id]}</a>
                    )}

                    <button className="open_special_exhibition_btn" onClick={() => openExhibitionSection(isSectionOpen)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.5" height="17" viewBox="0 0 9.5 17" className="arrow_down_icon">
                            <path d="M8,.5.5,8,8,15.5" transform="translate(0.5 0.5)" />
                        </svg>
                    </button>
                </section>
            )}
        </>
    );
};

export default Specialexhibition;

const SPECIAL_EXHIBITION_DATA = graphql`
    query SpecialExhibition {
        allSpecialExhibition {
            nodes {
                description
                hidden
                image_url
                link_more
                language_id
            }
        }
    }
`
