import React, { useState, useEffect } from "react";
import { Location } from '@reach/router';

import CookieConsent from 'react-cookie-consent';

import Specialexhibition from "./specialexhibition";
import Header from "./header";
import Footer from "./footer";
import GA4Tracking from './GA4Tracking'; // Ensure this path is correct

import "@yaireo/tagify/dist/tagify.css";
import "./styles.css";
import 'swiper/dist/css/swiper.min.css';
require("typeface-montserrat");

const Layout = (props) => {
    // State to manage cookie consent
    const [cookieConsent, setCookieConsent] = useState(false);

    useEffect(() => {
        // Check if consent has already been given
        const consentGiven = localStorage.getItem("gatsby-gdpr-google-analytics");
        setCookieConsent(consentGiven === "true");
    }, []);

    const handleAcceptCookie = () => {
        // Set consent in local storage and update state
        localStorage.setItem("gatsby-gdpr-google-analytics", "true");
        setCookieConsent(true);
    };

    return (
        <>
            <GA4Tracking trackingId="G-MN3QM782H6" isConsentGiven={cookieConsent} />

            <div className="main_content_flexbox_wrapper">
                <Specialexhibition props={props} />
                <Header props={props} />

                <main>
                    {props.children}
                </main>

                <CookieConsent
                    location="bottom"
                    buttonText="Akzeptieren"
                    declineButtonText="Decline"
                    cookieName="gatsby-gdpr-google-analytics"
                    onAccept={handleAcceptCookie}
                >
                    <span style={{ fontSize: "10px" }}>
                        Diese Website nutzt zu Analysezwecken den Dienst Google Analytics, ...
                    </span>
                </CookieConsent>

                <Footer props={props} />
            </div>
        </>
    );
};

export default Layout;
